$statusPatientGray: #afafaf;
$statusPatientYellow: #db9729;
$statusPatientRed: #a83333;
$statusPatientWhite: #ffffff;
$statusPatientBack: #000000;

$z-index-drawer: 1200;
$drawerWidth: 240;
$theme-spacing: 8;

.main-container {
  margin-top: 30px;
}

.logo-login {
  width: 90%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.logo-dashboad {
  width: 80%;
  max-width: 411px;
}

.badge-phq29 {
  width: 100%;

}

.card-patient-gray,
.card-patient-yellow,
.card-patient-red,
.card-patient-white,
.card-patient-black {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #8a8a8a;
  border-radius: 12px;
  margin: 3px;
  width: 98%;
  height: 80px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  color: #000000;
}


.card-patient-gray {
  background-color: $statusPatientGray;
}

.card-patient-yellow {
  background-color: $statusPatientYellow;
}

.card-patient-red {
  background-color: $statusPatientRed;
}

.card-patient-white {
  background-color: $statusPatientWhite;
}

.card-patient-black {
  background-color: $statusPatientBack;
  color: #ffffff;
}

.box-label-status-patient-gray {
  width: 20px;
  height: 20px;
  background-color: $statusPatientGray;
  color: $statusPatientGray;
  border: 1px solid #8a8a8a;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: -5px;
}

.box-label-status-patient-yellow {
  width: 20px;
  height: 20px;
  background-color: $statusPatientYellow;
  color: $statusPatientYellow;
  border: 1px solid #8a8a8a;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: -5px;
}

.box-label-status-patient-red {
  width: 20px;
  height: 20px;
  background-color: $statusPatientRed;
  color: $statusPatientRed;
  border: 1px solid #8a8a8a;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: -5px;
}

.box-label-status-patient-white {
  width: 20px;
  height: 20px;
  background-color: $statusPatientWhite;
  color: $statusPatientWhite;
  border: 1px solid #8a8a8a;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: -5px;
}

.box-label-status-patient-black {
  width: 20px;
  height: 20px;
  background-color: $statusPatientBack;
  color: $statusPatientBack;
  border: 1px solid #8a8a8a;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: -5px;
}

.btn-add-patient {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #000000;
  border-radius: 12px;
  margin: 3px;
  height: 80px;
  font-weight: bold;
  text-decoration: none;
  color: #000000;
  background-color: #eaeaea;
}
.btn-last-week {
  border: 4px solid #000000;
  padding: 5px 20px;
  width: 180px;
  text-align: center;
  margin-bottom: 10px;
}

.btn-last-week-filter {
  border: 4px solid #000000;
  width: 200px;
  text-align: center;
  margin-bottom: 10px;
}

.box-access-user {
  text-align: center;
  color: #ffffff;
  background-color: #414141;
  border: 2px solid #707070;
  padding: 10px;
  border-radius: 10px;
}
.box-info-user {
  color: #ffffff;
  border: 3px solid #000000;
  border-radius: 20px !important;
}
.box-info-user   h2 {
  text-align: center !important;
}
.box-info-user  > .MuiCardContent-root {
  background-color: #6E6E6E;
  color: #ffffff;
}
.box-info-user  button {
  margin-left: 20px;
  background-color: #EAEAEA;
  color: #000000;
}

.logo-dashboard {
  max-width: 380px;
  margin: auto;
}
@media (max-width: 992px) {
 .logo-dashboard {
    max-width: 98%;
  }
}

.card-check-ins{
  margin-right: 20px;
}
.card-check-ins h3{
  text-align: center;
}

/* ---------------- */
.root {
  display: flex;
}

.MuiToolbar-root.toolbar {
  padding-right: 24px; // keep right padding when drawer closed
}

.toolbarIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  min-height: 56px;
}

@media (min-width: 0px) and (orientation: landscape) {
  .toolbarIcon {
    min-height: 48px;
  }
}

@media (min-width: 600px) {
  .toolbarIcon {
    min-height: 64px;
  }
}

.MuiButtonBase-root.menuButton {
  margin-right: 36,
}

.MuiButtonBase-root.menuButtonHidden {
  display: none;
}

.MuiTypography-root.title {
  flex-grow: 1;
}

main {
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
}

.container {
  padding-top: #{4*$theme-spacing}px;
  padding-bottom: #{4*$theme-spacing}px;
}

.MuiPaper-root.paper {
  padding: #{2*$theme-spacing}px;
  overflow: auto;
  display: block;
}

.MuiAppBar-root.appBar {
  z-index: $z-index-drawer+1;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.MuiAppBar-root.appBarShift {
  margin-left: $drawerWidth;
  width: calc(100% - #{$drawerWidth}px);
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
};

.MuiDrawer-paper.drawerPaper {
  width: #{$drawerWidth}px;
  position: relative;
  white-space: nowrap;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}


.MuiDrawer-paper.drawerPaperClose {
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 72px;
}

.TableAddButton {
  float: right;
}

.icon-container {
  padding: 6px;
  display: inline-block;
  &-inside {
    min-width: 100px;
    min-height: 100px;
    padding: 16px;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    border-color: cadetblue;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .selected {
    background-color: aquamarine;
  }
}

.MuiBreadcrumbs-li {
  a {
    color: white;
    font-weight: 600;
  }
  p {
    color: white;
    font-weight: 600;
  }
}

.MuiBreadcrumbs-separator {
  color: white;
  font-weight: 600;
}

.MuiTableCell-body {
  a {
    color: #4673c3;
    text-decoration: none;
    font-weight: 600;
  }
}
// Helpers
.hidden {
  display: none!important;
}

.show {
  display: block!important;
}

.white {
  color: #ffffff!important;
}
.text-none {
  text-decoration: none;
}
